<template>
  <div class="view-wrap">
    <el-form :model="searchForm" class="F" label-width="80px">
      <el-form-item label="录单时间:" style="width: 300px">
        <el-date-picker
          v-model="searchForm.time"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          disabled
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="单号:"
        style="width: 260px; margin-right: 15px"
        label-width="60px"
      >
        <el-input
          type="text"
          placeholder=""
          v-model="searchForm.no"
          disabled
        ></el-input>
      </el-form-item>

      <el-form-item label="入库仓库:" style="width: 280px; margin-right: 15px">
       <el-input
          type="text"
          placeholder=""
          v-model="searchForm.wmsId"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item
        label="客户:"
        style="width: 280px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative">
          <el-input v-model="checkCustomName" disabled></el-input>
        </div>
      </el-form-item>
      
      <el-form-item label="销售员(制单人):" style="width: 330px" label-width="120px">
       <el-input
          type="text"
          placeholder=""
          v-model="searchForm.handler"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table
      :data="productList"
      border
      style="width: 100%"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column label="序号" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="productName"
        label="商品名称"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="productCode"
        label="商品编号"
        show-overflow-tooltip
      >
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="thisInventoryNum"
        label="当前库存"
        show-overflow-tooltip
      >
      </el-table-column> -->
      <el-table-column
        align="center"
        prop="unit"
        label="单位"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="num"
        label="数量"
        show-overflow-tooltip
        min-width="115px"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="price"
        label="单价"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.price ? scope.row.price.toFixed(2) : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="金额"
        show-overflow-tooltip
        prop="totalPrice"
      >
        <template slot-scope="scope">
          {{ scope.row.totalPrice?scope.row.totalPrice.toFixed(2):"-" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="discount"
        label="折扣率(%)"
        show-overflow-tooltip
        min-width="115px"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.discount ? scope.row.discount + "%" : "0%"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折后单价"
        show-overflow-tooltip
        prop="discountPrice"
      >
        <template slot-scope="scope">
          {{ scope.row.discountPrice }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折后金额"
        show-overflow-tooltip
        prop="discountTotalPrice"
      >
        <template slot-scope="scope">
          {{ scope.row.discountTotalPrice?scope.row.discountTotalPrice.toFixed(2):"-" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折扣优惠"
        show-overflow-tooltip
        prop="discountYouhui"
      >
        <template slot-scope="scope">
          {{ scope.row.discountYouhui }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="isGifts" label="赠品">
        <template slot-scope="scope">
          <input type="checkbox" v-model="scope.row.isGifts" disabled/>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="remark"
        label="备注"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getRefDetails } from "@/api/sale";
export default {
  data() {
    return {
      accountId: "",
      discount: 100,
      tabClickLabel: "",
      payment: 0,
      wmsList: [],
      checkCustomName:"",
      userCustomList: [],
      searchForm: {
        time: "",
        customerIds: "",
        no: "",
        handler: "",
        wmsId: "",
      },
      productList: [],
      productInfo:{},
      dialogVisible: false,
    };
  },
  created() {
    this.getRefDetails();
  },
  methods: {
    async getRefDetails() {
      let params = {
        id: this.$route.query.id,
      };
      let res = await getRefDetails(params);
      if (res.code == 1) {
        this.productList = res.data.list;
        this.productList.forEach((item) => {
          item.discountPrice = item.discountPrice / 100;
          item.discountTotalPrice = item.discountTotalPrice / 100;
          item.price = item.price / 100;
          item.totalPrice = item.totalPrice / 100;
          item.discountYouhui = (
            item.totalPrice - item.discountTotalPrice
          ).toFixed(2);
        });
        this.productInfo = res.data;
        this.searchForm.time = this.productInfo.entryDate;
        this.searchForm.wmsId = this.productInfo.wmsName;
        this.searchForm.no = this.productInfo.no;
        this.searchForm.handler = this.productInfo.createUserName;
        this.checkCustomName = this.productInfo.customerName;
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
        } else if (index === 4 || index === 6 || index === 9 || index === 10) {
          const values = data.map((item) => Number(item[column.property]));

          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          } else {
            sums[index] = "N/A";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
   backList() {
      this.$router.push({
        path: "/sale/retailReturnList/saleReturn",
      });
    },
  },
};
</script>

<style scoped>
.priceActive {
  color: #76d3fa;
}
.priceActive2 {
  color: #c295ff;
}
.priceActive3 {
  color: #f7ae4f;
}
.priceActive4 {
  color: #31b4a8;
}
.F {
  display: flex;
}
.FSB {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  padding: 15px 20px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.proName {
  line-height: 30px;
}
.proUnit {
  line-height: 30px;
}
.priceItem {
  width: 23%;
  height: 60px;
  border: 1px solid #facd91;
  text-align: center;
  line-height: 20px;
  margin-top: 15px;
  cursor: pointer;
}
.price {
  margin-top: 13px;
  font-style: 18px;
  font-weight: bold;
}
.priceType {
  font-size: 12px;
  color: #999;
}
.line {
  width: 4px;
  height: 20px;
  background-color: #35c093;
  margin-right: 10px;
}
</style>