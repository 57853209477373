import request from '@/until/request.js'
const prefixName = '/order/webOrder'
const prefixReturnName = '/order/webOrderRef'
// 查询零售单列表
export function getWebOrder(data) {
    return request({
        url: prefixName,
        method: 'get',
        params: {
            ...data
        }
    })
}
// 查询零售单详情
export function getSaleDetails(data) {
    return request({
        url: prefixName + '/details',
        method: 'get',
        params: {
            ...data
        }
    })
}
// 获取零售单号
export function getCode(data) {
    return request({
        url: prefixName + '/getCode',
        method: 'get',
        params: {
            ...data
        }
    })
}
// 创建零售单
export function setWebOrder(data) {
    return request({
        url: prefixName,
        method: 'post',
        data: data
    })
}
// 删除零售单
export function delWebOrder(id) {
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
// 查询零售退货单列表
export function getWebOrderRef(data) {
    return request({
        url: prefixReturnName,
        method: 'get',
        params: {
            ...data
        }
    })
}
// 查询零售退货单详情
export function getRefDetails(data) {
    return request({
        url: prefixReturnName + '/details',
        method: 'get',
        params: {
            ...data
        }
    })
}
// 获取零售退货单号
export function getRefCode(data) {
    return request({
        url: prefixReturnName + '/getCode',
        method: 'get',
        params: {
            ...data
        }
    })
}
// 创建零售退货单
export function setWebOrderRef(data) {
    return request({
        url: prefixReturnName,
        method: 'post',
        data:data
    })
}
// 删除零售退货单
export function delRefWebOrder(id) {
    return request({
        url: prefixReturnName + '/' + id,
        method: 'delete'
    })
}
//查询销售明细表
export function getOrderDetail(data) {
    return request({
        url: '/order/webOrderRpt/orderDetail',
        method: 'get',
        params: {
            ...data
        }
    })
}
//查询销售明细表合计
export function getOrderDetailCombined(data) {
    return request({
        url: '/order/webOrderRpt/orderDetailCombined',
        method: 'get',
        params: {
            ...data
        }
    })
}
//查询销售汇总表（按商品）
export function getSummaryProduct(data) {
    return request({
        url: '/order/webOrderRpt/summaryProduct',
        method: 'get',
        params: {
            ...data
        }
    })
}
//查询销售汇总表（按客户）
export function getSummaryCustomer(data) {
    return request({
        url: '/order/webOrderRpt/summaryCustomer',
        method: 'get',
        params: {
            ...data
        }
    })
}